// Angular imports
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Third-party imports
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from "ngx-bootstrap-spinner";
import { ToastrModule } from 'ngx-toastr';

// Application imports
import { AppComponent } from 'app/app.component';
import { CoreCommonModule } from '@core/common.module';
import { CoreModule } from '@core/core.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';
import { ErrorInterceptor, JwtInterceptor } from './auth/helpers';
import { LayoutModule } from 'app/layout/layout.module';
import { LoaderInterceptor } from '../@core/interceptors/loader.interceptor';
import { MiscellaneousModule } from './main/pages/miscellaneous/miscellaneous.module';
import { SecurityInterceptor } from '../@core/interceptors/security.interceptor';
import { SharedModule } from './main/shared/shared.module';

import 'hammerjs';
import { coreConfig } from 'app/app-config';
import { SquareCardComponent } from './main/shared/square-card/square-card.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./main/pages/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path:'home',
    loadChildren: () => import('./main/pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./main/dashboards/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: '**',
    redirectTo: '' //Error 404 - Page not found
  }
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    // Angular modules
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy'
    }),

    // Third-party modules
    NgbModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),

    // Core and shared modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,
    SharedModule,

    // Feature modules
    LayoutModule,
    MiscellaneousModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SecurityInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
